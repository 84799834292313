import React from "react"
import { Link } from "gatsby"

export default function Navbar() {
    return (
      <nav className="flex items-center justify-between flex-wrap p-6 fixed w-full bg-white z-10">
        <div className="flex items-center flex-shrink-0 mr-6">
          <Link
            to="/"
            className="font-semibold text-xl tracking-tight brand hover:text-pink transition-all duration-300 no-link-effect"
          >
            <span className="text-pink transition-all duration-300">w/</span>
            FahimMurshed
          </Link>
        </div>
        <div className="block lg:hidden">
          <button
            id="hamburger"
            className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-pink hover:border-pink outline-none"
            onClick={() =>
              document.getElementById("main-menu").classList.toggle("hidden")
            }
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          id="main-menu"
          className="hidden w-full flex-grow lg:flex lg:items-center lg:w-auto lg:text-right"
        >
          <div className="lg:flex-grow text-lg">
            <Link
              to="/"
              activeClassName="active-nav"
              className="no-link-effect nav-item block lg:inline-block lg:mt-0 m-4"
            >
              root
            </Link>
            <Link
              to="/about"
              activeClassName="active-nav"
              className="no-link-effect nav-item block lg:inline-block lg:mt-0 m-4"
            >
              about
            </Link>
            <Link
              to="/pets"
              partiallyActive={true}
              activeClassName="active-nav"
              className="no-link-effect nav-item block lg:inline-block lg:mt-0 m-4"
            >
              pets
            </Link>
            <Link
              to="/showcase"
              partiallyActive={true}
              activeClassName="active-nav"
              className="no-link-effect nav-item block lg:inline-block lg:mt-0 m-4"
            >
              showcase
            </Link>
            <Link
              to="/mentions"
              partiallyActive={true}
              activeClassName="active-nav"
              className="no-link-effect nav-item block lg:inline-block lg:mt-0 m-4"
            >
              mentions
            </Link>
            <Link
              to="/blog"
              partiallyActive={true}
              activeClassName="active-nav"
              className="no-link-effect nav-item block lg:inline-block lg:mt-0 m-4"
            >
              blog
            </Link>
            <Link
              to="/contact"
              activeClassName="active-nav"
              className="no-link-effect nav-item block lg:inline-block lg:mt-0 m-4"
            >
              contact
            </Link>
          </div>
        </div>
      </nav>
    )
}